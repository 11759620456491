import React, { useEffect, useState, useRef } from 'react';
import Button from '../Header/Button';
import './cta.css';

const Cta = ({message}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
  
    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsScrolled(entry.isIntersecting);
    };
  
    const observer = new IntersectionObserver(observerCallback, observerOptions);
  
    if (headerRef.current) {
      const headerCurrent = headerRef.current; // Store the current ref value
      observer.observe(headerCurrent);
      
      return () => {
        observer.unobserve(headerCurrent); // Use the stored ref value in cleanup
      };
    }
  }, []); // Empty dependency array since we're not using any external values
  

  return (
    <div className={`siteHub__cta--home ${isScrolled ? 'scale-up-center-home view__page' : 'none__view'}`} ref={headerRef}>
      <div className='siteHub__cta-content'>
        <h2 style={{ color: '#FFFFFF' }}>{message}</h2>
        <h4 style={{ color: '#FFFFFF' }}>Plans start as low as £5.49 user/pm</h4>
      </div>
      <Button clBlack={true} link={"/contact"} text={"Start Your Journey"}/>
    </div>
  );
};

export default Cta;





