import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import "./Reusable.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import ErrorPage from "./Pages/Error/ErrorPage";
import Home from "./Pages/Home/Home";
import Contact from "./Pages/Contact/Contact";
import Pricing from "./Pages/Pricing/Pricing";
import Privacy from "./Pages/Legal/Privacy";
import TermsOfService from "./Pages/Legal/TermsOfService";
import Landing from "./Pages/Landing/Landing";

const router = createBrowserRouter([
  {
    path: "/contact",
    element: <Contact />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/Learn-more",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/legal/privacy",
    element: <Privacy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/legal/termsOfService",
    element: <TermsOfService />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
serviceWorker.unregister();
