import './AchieveMore.css'
import { FaArrowRight } from 'react-icons/fa'
import Button from '../Header/Button'
const AchieveMore = () => {
  return (
    <div className='achieve__more center__flex' id='possibility'> 
      <div className="achieve__more--text">
          <h2>Unlock Unmatched Efficiency</h2>
          <h3>Save more than 20 hours per week on management tasks, enabling you to provide swift oversight to your teams. As a result, your teams will also gain back valuable hours, boosting productivity and accelerating project progress.</h3>
            <div>
              <Button clBlack={true} link={"/contact"} text={"Start today"}/>
              <Button link={"/Features/Features.html"} text={"See how it works"}/>
            </div>
      </div>
    </div>
  )
}

export default AchieveMore