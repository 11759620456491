import './Contact.css';
import HomeNav from '../Home/HomeComponents/Nav/HomeNav';
import HomeContact from '../Home/HomeComponents/HomeContact/HomeContact';
import Footer from '../Home/HomeComponents/footer/Footer';

const Contact = ({setError}) => {

  return (
    <>
    <HomeNav />
    <div className="contact-page center__flex">
      <div className="contact__info__sitehub">
        <div>
          <h2>Contact our customer support team</h2>
          <p>Have questions or need help with your account? We’re here for you!</p>
          <p>02033764233</p>
          <a target="_blank" rel="noreferrer" href="mailto:support@sitehubapp.com">
            <p>support@sitehubapp.com</p>
          </a>
        </div>
        <div>
          <h2>Contact our sales team</h2>
          <p>Want to learn more about joining siteHUB?</p>
          <p>02033764233</p>
          <a target="_blank" rel="noreferrer" href="mailto:sales@sitehubapp.com">
            <p>sales@sitehubapp.com</p>
          </a>
        </div>
      </div>
      <div className="home__contact__text">
        <h2>Leave your details for a specialist to contact you...</h2>
        <div className='center__flex'>
          <HomeContact setError={setError}/>
        </div>
      </div>
      </div>
    <Footer />
    </>
  );
};

export default Contact; 




