import React from 'react'
import { FaArrowRight } from 'react-icons/fa';
import './Button.css'
const Button = ({ link, text, clBlack }) => {
  // "https://web.sitehubapp.com/
  return (
    <a className={`re__use__button ${clBlack ? 'black__button' : ''}` } href={link}>
      <h5>{text}</h5>
      <button><FaArrowRight/></button>
    </a>
  );
};

export default Button