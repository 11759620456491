import  { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Home/HomeComponents/footer/Footer';
import HomeNav from '../Home/HomeComponents/Nav/HomeNav';
import PriceTable from '../PaymentPlans/PriceTable';
import './Pricing.css';

const Pricing = () => {

  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setIsScrolled(true);
    }, 550); // 550 milliseconds (0.55 seconds) delay

    const handleScroll = () => {
      const headerElement = headerRef.current;
      if (headerElement) {
        setIsScrolled(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [timeFrame, setTimeFrame] = useState('monthly')

  const pricingOptions = [
    {
      location: 'gb',
      plans: [
        { userAmount: 5, planName: 'basic', planDescription: 'Essential software for operating a small/medium construction business', save: timeFrame === 'monthly' ? '' : '£139.41', price: timeFrame === 'monthly' ? '£119.95' : '£1299.99', features: ['5 active users', 'Project management', 'Inventory management', 'Customer Portal', '& Much more'] },
        { userAmount: 20, features: ['20 active users', 'Everything from basic', '24/7 support'] , planName: 'professional', planDescription: 'Essential software, increased user capacity and support', save: timeFrame === 'monthly' ? '' : '£239.89', price: timeFrame === 'monthly' ? '£199.99' : '£2159.99', mostPopular: true },
        { userAmount: 50, features: ['50 active users', 'Everything from professional', 'Free data upload', 'One-to-one onboard support'], planName: 'premium', planDescription: 'Our most robust plan, 24/7 support and onboarding included', save: timeFrame === 'monthly' ? '' : '£329.40', price: timeFrame === 'monthly' ? '£274.95' : '£2970.00' },
      ],
    },
    {
      location: 'aus',
      plans: [
        { userAmount: 5, planName: 'siteHUB basic - Australia', planDescription: 'siteHUB basic plan', price: '$219.95' },
        { userAmount: 20, planName: 'siteHUB professional - Australia', planDescription: 'siteHUB professional plan', price: '$379.95', mostPopular: true },
        { userAmount: 50, planName: 'siteHUB premium - Australia', planDescription: 'siteHUB premium plan', price: '$524.95' },
      ],
    },
    {
      location: 'us',
      plans: [
        { userAmount: 5, planName: 'siteHUB basic - United states', planDescription: 'siteHUB basic plan', price: '$149.95' },
        { userAmount: 20, planName: 'siteHUB professional - United states', planDescription: 'siteHUB professional plan', price: '$249.95', mostPopular: true },
        { userAmount: 50, planName: 'siteHUB premium - United states', planDescription: 'siteHUB premium plan', price: '$344.95' },
      ],
    },
  ];

  const plans = pricingOptions.find((option) => option.location === 'gb')?.plans || [];


  return (
    <>
    <HomeNav />
    <div className='pricing__page' >
      <div style={{margin: '4rem'}}/>
      <div className='center__flex head__options__price'>
        <h2>The right pricing package for every construction team</h2>
        <div>
          <span className={`${timeFrame === 'monthly' ? 'chosen' : ''} span__hover center__flex`} onClick={()=>setTimeFrame('monthly')}>
            <h4>
              Monthly
            </h4>
          </span>
          <span className={`${timeFrame === 'annually' ? 'chosen' : ''} span__hover center__flex`} onClick={()=>setTimeFrame('annually')}>
            <h4>
              Annually
            </h4>
          </span>
        </div>
      </div>
      {/* <div className={`price__table--page ${isScrolled ? 'scale-up-center-home view__page' : 'none__view'}`} ref={headerRef}> */}
        <div className="price__plans">
          {plans.map((plan, index) => (
            <PriceTable key={index} {...plan}  timeFrame={timeFrame}/>
          ))}
        </div>
      {/* </div> */}
      <div className='center__flex head__options__price'>
        <h4>Not sure which plans for you?</h4>
        <Link to={"/contact"}>
          <button>Speak to the team</button>
        </Link>
      </div>
    </div>
      <Footer />
    </>

  );
};

export default Pricing;
