import { Link } from 'react-router-dom'
import lap from '../Assets/ProjectDashLap.png'
import mob from '../Assets/ProjectDashMob.png'
import './Cta.css'
const Cta = () => {
  return (
    <div className="feature__head__container">
    <div className="feature__head features__head__standard">
      <div className="feature__head__overlay"></div>
      <div className="feature__head__lap">
        <img
          src={lap}
          alt="construction project management made simple, easy and accessible using siteHUB management software"
        />
      </div>
      <div className="feature__head__text">
        <div>
          <h2>Project management made simple with siteHUB</h2>
          <h3>As low as £5.40 a month per user</h3>
          <p>
            Unlock effortless project management with siteHUB: Streamline
            your workflow, boost productivity, and achieve construction
            success with ease. Discover how siteHUB simplifies every aspect
            of your projects.
          </p>
          <a href="/Pricing">
            <h5>get started today</h5>
          </a>
          <a target='_blank' rel="noreferrer" href="/Meeting-schedule.html">
            <h5>Want to know some more?</h5>
          </a>
        </div>
        <img
          src={mob}
          alt="Reducing construction project lead time, less material wastage and a safer work site environment for you building team with siteHUB"
        />
      </div>
    </div>
  </div>
  )
}

export default Cta