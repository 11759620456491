import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './PriceTable.css'
const PriceTable = ({planName, planDescription, price, save, mostPopular, timeFrame, features}) => {

  return (

            <div className={`price__plan ${mostPopular ? 'most__popular' : ''}`}>
              <div className="price__plan--head-container direction__column flex-container">
                <div className="price__plan--head flex-container">
                  {mostPopular && (
                    <div className="most__popular--text">
                      most popular
                    </div>
                  )}
                  <div className="priceColumn-name flex-container">
                    <span>siteHUB {planName}</span>
                  </div> 
                  <p>{planDescription}</p>
                </div>
                <Link to={`https://web.sitehubapp.com?ref=add=true&plan=${planName}&billing=${timeFrame}`}>
                  <button className='span__hover' >Subscribe now</button>
                  </Link>
                <div className="price__plan--price flex-container direction__column">
                  <div className="price__label">
                    <h2>{price}</h2>
                    <span>per <br/>{timeFrame === 'monthly' ? 'month' : 'year'}</span>
                  </div>
                  {save && (
                    <h4 style={{textAlign: 'center'}}>Save {save} annually</h4>
                  )}
                  <div className="price__features">
                    <ul>
                      {features.map((f,index)=>(
                        <li key={index}>
                          <span className='center__flex'><FaCheck /></span>
                          <p>{f}</p>
                        </li>
                        ))}
                    </ul>
                  </div>
      
                </div>
              </div>
            </div>
  );
};

export default PriceTable;

