import React, { useState } from 'react';
import './HomeContact.css';

const HomeContact = () => {

  const currentDate = new Date(Date.now());
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const formattedDate = currentDate.toLocaleString('en-US', options);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    additionalInfo: '',
    date: formattedDate,
  });



  const [lastSubmitted, setLastSubmitted] = useState(Date.now() - 60000);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [alerts, setAlerts] = useState('')

  const isFilled = formData.fullName !== '' && formData.email !== ''
  const handleSubmit = async (e) => {
    e.preventDefault();
    const now = Date.now();
    if (now - lastSubmitted >= 60000) { // checks if it's been a minute since last submission
      try {
        setLastSubmitted(now);
        const EMAIL__MESSAGE = {        
          from: 'sam@sitehubapp.com',
          reply: 'sam@sitehubapp.com',
          to: formData.email,
          message: {
            subject: `I've received your details, ${formData.fullName}`,
            html: 
        `
        Hey ${formData.fullName}, <br />
        
        Thanks for leaving your details on our website. <br />
        
        I'll be in touch in the soon.<br />
        
        Looking forward to speaking some more. <br />
        
        Sam <br />
        siteHUB
        `
          },              
        }
        
        
        
            const response = await fetch(
              "https://us-central1-sitehubwebapp.cloudfunctions.net/sendEmailWithFirebase",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ EMAIL__MESSAGE, formData }),
              }
            );
            if (response.ok) {
              setFormData({
                fullName: '',
                email: '',
                phoneNumber: '',
                companyName: '',
                additionalInfo: '',
              });
              setAlerts("We've received your details. We'll be in touch soon! Check your junk folder if your main inbox doesn't have a message from one of the team.")
              return response.ok;
            } else {
              console.log(response);
              return response.status;
            }
      
        // Clear the form
      } catch (error) {
        setAlerts('There was an error submitting your data, please try again');
      }
    } else {
      setAlerts('Please wait for a minute before submitting again.');
    }
  };

  return (
    <>
      {alerts && (
        <div className='error__alert pop-up'>
          <h3>Alert</h3>
          <p>{alerts}</p>
          <div className="error__alert--button">
            <button onClick={()=>{setAlerts('')}}>Ok</button>
          </div>
        </div>
      )}
        <form onSubmit={handleSubmit}>
          <div className="form__option">
            <p>Your full name</p>
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Your email</p>
            <input type="email" name="email" value={formData.email} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Your phone number</p>
            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Your company name</p>
            <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} />
          </div>
          <div className="form__option">
            <p>Any further information?</p>
            <textarea name="additionalInfo" value={formData.additionalInfo} onChange={handleChange} />
          </div>
          <button disabled={!isFilled} className={isFilled ? 'span__hover' : 'disabled'} type="submit">Submit</button>
        </form>
    </>
  );
};

export default HomeContact;
