import {  FaArrowRight } from 'react-icons/fa'
import { useEffect, useState, useRef } from 'react';
import './Header.css'

import constructionIpad from '../../Assets/constructionIpad.png'
import craneBackdrop from '../../Assets/craneBackdrop.png'
import perosnIpad from '../../Assets/perosnIpad.png'
import timeSheets from '../../Assets/timeSheets.png'
import hnsInc from '../../../Assets/hnsInc.png'
import timeSheetSignIn from '../../../Assets/timeSheetSignIn.png'
import contactView from '../../../Assets/contactDash.png'
import ProjectImg from '../../../Assets/Project.png'
import Slider from './Slider'
import Button from './Button';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        setIsScrolled(true);
      }, 550); // 550 milliseconds (0.55 seconds) delay
  
      const handleScroll = () => {
        const headerElement = headerRef.current;
        if (headerElement) {
          setIsScrolled(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  

  const dashSlide = [
    {
      img: hnsInc,
      alt: "Health and safety dashboard included in siteHUB project management software"
    }, 
    {
      img: ProjectImg,
      alt: "Project completion time improved with siteHUB project management software"
    },
    {
      img: timeSheetSignIn,
      alt: "Construction teams easily access to signing in and out of work from anywhere office or field with siteHUB"
    },
    {
      img: constructionIpad,
      alt: "Construction professionals utilizing project management software"
    },
    {
      img: contactView,
      alt: "Contact dashboard, improving communication for construction companies"
    },
    {
      img: craneBackdrop,
      alt: "Industrial construction projects completed on time and within budget using siteHUb project management software"
    },
    {
      img: timeSheets,
      alt: "View construction team time sheets using siteHUB project management software"
    },
    {
      img: perosnIpad,
      alt: "Construction project manager using siteHUB to improve company financial forecasting"
    },
  ];

  return (
    <div className={`siteHub__header center__flex ${isScrolled ? 'scale-up-center-home view__page' : 'none__view'}`} ref={headerRef} id='home'>
      <div className="landing__header__bg"></div>
      <div className="siteHub__header--backdrop">
        <div className="siteHub__home--text">
          <h1>Construction <br /> management software</h1>
          <h3>Everything you need to scale, maintain and maximize company profits and potential growth</h3>
          <div className="siteHub__home--links">
            <div>
              <Button link={"https://web.sitehubapp.com/"} text={"Start Today"}/>
              <Button clBlack={true} link={"/Features/projectManagement.html"} text={"See how it works"}/>
            </div>
            <div className="siteHub__home--join">
              join 500+ companies elevating their businesses
            </div>
          </div>
        </div>
        <Slider toLoop={dashSlide} speed={'0.4'} customCLass={'dash__slide--images'}/>
      </div>
    </div>
  )
}

export default Header