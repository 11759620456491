import React from 'react'
import './Intro.css'
import invItem from '../Assets/invItem.png'
import inv from '../Assets/inv.png'

const Intro = () => {
  return (
    <div className='flex__column intro__section'>
      <div className="center__flex">
        <h2>Save hours a week by <strong>Removing guess work</strong> & manual labour to keep your stock lists up to date</h2>
      </div>
      <div className="intro__img__cont">
        <img src={invItem} alt="" />
        <img src={inv} alt="" />
      </div>
    </div>
  )
}

export default Intro