import {  FaHardHat, FaList, FaLongArrowAltRight, FaPeopleCarry, FaUser } from 'react-icons/fa'

const HomeLink = () => {
  return (
    <div className='home__link'>
      <div className="home__link__left center__flex">
        <h2>siteHUB Advanced Construction Software</h2>
        <p>Unlock a comprehensive suite of tools designed to optimize cost savings, streamline operations, and strengthen professional partnerships.</p>
      </div>
      <div className="home__link__right">
          <div className="home__link__option">
            <FaHardHat style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
            <div>
              <h3>Comprehensive Management Suite</h3>
              <p>Effortlessly oversee your construction projects with our feature-rich management tools, ensuring smooth operations from start to finish.</p>
            </div>
          </div>
          <div className="home__link__option">
            <FaPeopleCarry style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
            <div>
              <h3>Collaborative Teams</h3>
              <p>Foster teamwork and synergy among your project teams, improving communication and productivity throughout your construction projects.</p>
            </div>
          </div>
          <div className="home__link__option">
            <FaList style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
            <div>
              <h3>Efficient Inventory Solutions</h3>
              <p>Optimize your inventory management with our solutions, ensuring you have the right materials at the right time to avoid delays.</p>
            </div>
          </div>
          <div className="home__link__option">
            <FaUser style={{fontSize: '3rem', color: 'rgb(106, 168, 239)'}} />
            <div>
              <h3>Customer-Focused Portal</h3>
              <p>Enhance customer relationships with our dedicated portal, providing clients with real-time updates and transparency throughout projects.</p>
            </div>
          </div>
      </div>
    </div>
  )
}

export default HomeLink