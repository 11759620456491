import React from 'react'
import './ProjectManagement.css'
import projectSiteView from '../../Assets/projectSiteView.png'

const ProjectManagement = () => {
  return (
    <div className='project__management--home' id='features'>
      <div className='project__management--home-text'>
        <h2>Streamline Construction Management for Enhanced Efficiency</h2>
        <p>Empower your construction projects with siteHUB's construction management platform. Seamlessly oversee every aspect of your construction endeavors, from planning and execution to monitoring and completion.</p>
      </div>
      <div className='project__management--home-image'>
        <img src={projectSiteView} alt="project management for construction companies using siteHUB" srcSet="" loading="lazy" />
      </div>
    </div>
  )
}

export default ProjectManagement
