import React from "react";
import { useNavigate } from "react-router-dom";
import './ErrorPage.css'
function ErrorPage() {
  const navigate = useNavigate()
  const home = async () => {
    navigate('/');
  }
  return (
    <div className="error-page">
      <div className="error-logo">
        <h1>siteHUB</h1>
      </div>
      <h1>Something went wrong!</h1>
      <p className="error-msg">Please try again later or contact support for assistance.</p>
      <span onClick={home} className="home-link">Take me to home</span>
    </div>
  );
}

export default ErrorPage;