import React from 'react'
import Footer from '../Home/HomeComponents/footer/Footer'
import HomeNav from '../Home/HomeComponents/Nav/HomeNav'


const TermsOfService = () => {
  return (
    <div className='legal__page'>
      <HomeNav />
      <h1>Terms of service</h1>
      <h2>1. Use of Services:</h2>
<p> 
  a. Eligibility: You must be of legal age and have the authority to enter into this agreement to use our Services.
  <br/>
  b. Account: To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
</p>
<h2>2. User Content:</h2>
<p> 
  a. Responsibility: You retain ownership of the content you provide to our Services ("User Content"). You are responsible for the legality, accuracy, and appropriateness of your User Content.
  <br/>
  b. License: By providing User Content, you grant siteHUB a worldwide, royalty-free, non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, and display such User Content for the purpose of providing our Services.
</p>
<h2>3. Prohibited Conduct:</h2>
<p>
  You agree not to use our Services for any unlawful, harmful, or unauthorized purposes. Prohibited conduct includes, but is not limited to:
  <br/>
  a. Uploading or transmitting malicious software, viruses, or harmful code.
  <br/>
  b. Interfering with the security or integrity of our Services or other users' accounts.
  <br/>
  c. Engaging in fraudulent activities or misrepresenting your identity.
</p>
<h2>4. Intellectual Property:</h2>
<p>
  a. Ownership: All content and materials provided by siteHUB, including software, logos, and designs, are the property of siteHUB and protected by intellectual property laws.
  <br/>
  b. Restrictions: You may not use, copy, or distribute our intellectual property without our explicit permission.
</p>
<h2>5. Privacy Policy:</h2>
<p>
  Our Privacy Policy describes how we collect, use, and protect your Personal Information. By using our Services, you agree to our Privacy Policy.
</p>
<h2>6. Third-Party Links:</h2>
<p>
  Our Services may contain links to third-party websites. We are not responsible for the content or practices of these third-party sites. Your use of such sites is at your own risk.
</p>
<h2>7. Termination:</h2>
<p>
  We reserve the right to suspend or terminate your access to our Services if you violate these Terms or engage in any harmful activities.
</p>
<h2>8. Disclaimer of Warranty:</h2>
<p>
  Our Services are provided on an "as is" and "as available" basis. We do not warrant that our Services will be error-free or uninterrupted.
</p>
<h2>9. Limitation of Liability:</h2>
<p>
  To the maximum extent permitted by law, siteHUB shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of our Services.
</p>
<h2>10. Governing Law:</h2>
<p>
  These Terms shall be governed by and construed in accordance with the laws of [your country or state].
</p>
<h2>11. Changes to Terms:</h2>
<p>
  We may update these Terms from time to time. Any changes will be posted on our website.
</p>
<h2>
        How to contact us:
      </h2>
      <h3>
        For any questions or concerns about your privacy, please reach out to our support team.
        At siteHUB, we are dedicated to respecting your privacy, ensuring the security of your information, and providing a reliable and efficient construction software platform.
      </h3>
      <Footer />
    </div>
  )
}

export default TermsOfService