import { FaCogs, FaDatabase, FaSmile, FaUsers } from 'react-icons/fa';
import './FeatureInfoSection.css'
const FeatureInfoSection = () => {
  return (
<div className='feature__info' id='wsiteHUB'>
  <div class="feature__info--container">
    <span><FaSmile/></span>
    <h2>Simple</h2>
    <p>Fast and easy-to-use software that automates repetitive management tasks, simplifying your workflow.</p>
  </div>
  <div class="feature__info--container">
    <span><FaUsers/></span>
    <h2>Communicate</h2>
    <p>Provide individual accounts for all your employees, enabling efficient communication and collaboration within your team.</p>
  </div>
  <div class="feature__info--container">
    <span><FaDatabase/></span>
    <h2>Concise</h2>
    <p>Maintain complete control of your business operations, synchronize databases, and receive real-time analysis for informed decision-making.</p>
  </div>
  <div class="feature__info--container">
    <span><FaCogs/></span>
    <h2>Control</h2>
    <p>Stay on top of meetings, deadlines, and the ever-changing progress of your projects, giving you greater control over your business.</p>
  </div>
</div>

  )
}

export default FeatureInfoSection