import React from 'react'
import Footer from '../Home/HomeComponents/footer/Footer'
import HomeNav from '../Home/HomeComponents/Nav/HomeNav'
import './Legal.css'
const Privacy = () => {
  return (
    <div className='legal__page'>
      <HomeNav />
      <div className="legal__content">

      <h1>siteHUB privacy policy</h1>
      <h2>
        At siteHUB, we value your privacy and are deeply committed to safeguarding any information we collect from or about you. This Privacy Policy outlines how we handle your Personal Information when you use our construction software services, applications, and website collectively referred to as "Services."
      </h2>

      <h2>
        Information we collect:
      </h2>
      <p>
        We gather Personal Information through various means, such as when you create an account, communicate with us, or interact with our Services.<br/>
        Account Information: When you create an account, we collect details like your name, contact information, payment card details, and transaction history.<br/>
        User Content: Any information you provide in your interactions with our Services, including input, file uploads, or feedback, falls under User Content.<br/>
        Communication Information: When you communicate with us, we collect your name, contact information, and message contents.<br/>
        Social Media Information: If you interact with our social media pages, we may collect the information you choose to share.<br/>
      </p>
      <h2>
        How we use Personal Information:
      </h2>
      <p>
        We use Personal Information for various purposes, including improving and analyzing our Services, communication with you, developing new features, preventing fraud, and complying with legal obligations.
      </p>
      <h2>
        Disclosure of Personal Information:   
      </h2>
      <p>
        We may share your Personal Information with certain third parties under specific circumstances, such as vendors, service providers, and affiliates, to assist with business operations or in connection with a business transfer.
      </p>
      <h2>
        Your rights:
      </h2>
      <p>
        Depending on your location, you may have certain rights regarding your Personal Information, including access, deletion, correction, and objections. You can exercise these rights through your siteHUB account or by contacting us.
      </p>
      <h2>
        Security and retention:
      </h2>
      <p>
        We employ measures to protect your Personal Information from unauthorized access, and we retain it only as long as necessary for legitimate business purposes.
      </p>
      <h2>
        International users:
      </h2>
      <p>
        By using our Services, you acknowledge that your Personal Information may be processed and stored in the United States and disclosed to affiliates and service providers in other jurisdictions.
      </p>
      <h2>
        Changes to the privacy policy:
      </h2>
      <p>
        We may update this Privacy Policy, and any changes will be posted on this page.
      </p>
      <h2>
        How to contact us:
      </h2>
      <h3>
        For any questions or concerns about your privacy, please reach out to our support team.
        At siteHUB, we are dedicated to respecting your privacy, ensuring the security of your information, and providing a reliable and efficient construction software platform.
      </h3>
      </div>
    <Footer />
    </div>
  )
}

export default Privacy