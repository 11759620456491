import Footer from '../Home/HomeComponents/footer/Footer'
import './Landing.css'
import Nav from '../Home/HomeComponents/Nav/HomeNav'
import Cta from './Components/Cta/Cta'
import Header from './Components/Header/Header'
import HomeLink from './Components/HomeLink'
import Intro from './Components/Intro/Intro'

const Landing = () => {
  return (
    <div className='home__page'>
      <Nav />
      <Header />
      <Intro />
      <HomeLink />
      <div className="save__money__msg center__flex">
        <h2>Hours of work, <span> in minutes </span></h2>
      </div>
      <Cta />
      <Footer />
  </div>
  )
}

export default Landing