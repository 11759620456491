import './Header.css'
import headImg from '../Assets/ProjectSchedulingCal.png'
import { FaLongArrowAltRight } from 'react-icons/fa'
const Header = () => {
  return (
    <div className='landing__header center__flex'>
      <div className="landing__header__bg"></div>
      <div className="landing__header__content">
        <div className="landing__header__content__left">
          <h2>Reduce project lead time, inventory wastage & unexpected errors from your team</h2>
          <p>Accelerate progress and unlock potential with our powerful system that connects your data, workflows and team.</p>
          <a href="/Meeting-schedule.html">
            <button>Speak to the team <span className='center__flex'><FaLongArrowAltRight/></span></button>
          </a>
        </div>
        <div className='landing__header__img'>
          <img src={headImg} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Header