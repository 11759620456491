import Button from '../Header/Button'
import './FreeView.css'

const FreeView = () => {
  return (
    <div className="free__view">
      <div className="feature__head__overlay"></div>
      <h2>Discover Project Management Solutions </h2>
      <div className="flex__end">
        <Button link={"/contact"} text={"Contact sales"}/>
        <Button clBlack={true} link={"https://web.sitehubapp.com/"} text={"Start now"}/>
      </div>
    </div>
  )
}

export default FreeView