import React from 'react'
import './footer.css'

const Footer = () => {

  return (
    <div class="siteHub section__padding" id="footer">
      <div class="siteHub-links">
        <div class="siteHub-links_logo">
          <h2>siteHUB</h2>
          <p>86-90, Paul Street, London, EC2A 4NE UK. All Rights Reserved</p>
        </div>
        <div class="siteHub-links_div">
          <h4>Media</h4>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/sitehubapp/">
              <p>Instagram</p>
            </a> 
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/sitehubapp/">
              <p>LinkedIn</p>
            </a> 
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100091731523402">
              <p>Facebook</p>
            </a> 
            <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UChaOD_RPlxGs5fOCFx-FkLw">
              <p>Youtube</p>
            </a>
          </div>

        <div class="siteHub-links_div">
          <h4>Company</h4>
          <a href="/legal/termsOfService">
            <p>Terms of service</p>
          </a>
          <a href="/legal/privacy">
            <p>Privacy Policy</p>
          </a>
          <a href="/contact">
            <p>Contact</p>
          </a>
        </div>

        <div class="siteHub-links_div">
          <h4>Get in Touch</h4>
          <p>86-90, Paul Street, London, EC2A 4NE</p>
          <p>United Kingdom - 02033764233</p>
          <p>support@sitehubapp.com</p>
        </div>
      </div>
      <div class="siteHub-copyright">
        <p>2023 siteHUB. ALL RIGHTS RESERVED</p>
        <p>Part of the consultAI family</p>
      </div>
    </div>
  )
}

export default Footer