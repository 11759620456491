import Footer from './HomeComponents/footer/Footer'
import './Home.css'
import AchieveMore from './HomeComponents/AchieveMore/AchieveMore'
import FeatureInfoSection from './HomeComponents/FeatureInfoSection/FeatureInfoSection'
import Header from './HomeComponents/Header/Header'
import Nav from './HomeComponents/Nav/HomeNav'
import ProjectManagement from './HomeComponents/ProjectManagementSection/ProjectManagement'
import ContactView from './HomeComponents/Contacts/ContactView'
import Cta from './HomeComponents/cta/Cta'
import HomeLink from './HomeComponents/HomeLink/HomeLink'
import ProjectLink from './HomeComponents/cta/ProjectLink'
import FreeView from './HomeComponents/FreeView/FreeView'
import HomeContact from './HomeComponents/HomeContact/HomeContact'
import React, { useEffect, useState, useRef } from 'react';
const Home = () => {

  const [isScrolled, setIsScrolled] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
  
    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsScrolled(entry.isIntersecting);
    };
  
    const observer = new IntersectionObserver(observerCallback, observerOptions);
  
    if (headerRef.current) {
      const headerCurrent = headerRef.current; // Store the current ref value
      observer.observe(headerCurrent);
      
      return () => {
        observer.unobserve(headerCurrent); // Use the stored ref value in cleanup
      };
    }
  }, []); // Empty dependency array since we're not using any external values
  
  return (
    <div className='home__page'>

      <Nav />
      <Header/>
      <FeatureInfoSection />
      <ProjectLink />
      <HomeLink />
      <FreeView />
      <ProjectManagement />
      <Cta message={'Ready to get started?'}/>
      <AchieveMore/>
      <ContactView />
      <div style={{marginBottom: '5rem'}} className={`home__contact__text ${isScrolled ? 'scale-up-center-home view__page' : 'none__view'}`} ref={headerRef}>
        <h2>Ready to transform your business?</h2>
        <div className="center__flex">
          <HomeContact/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home