import teamImage from '../../Assets/teamImage.png'
import Button from '../Header/Button'
import './ContactView.css'
const ContactView = () => {
  return (
    <div className='project__management--home'>
      <div className='project__management--home-text'>
        <h2>Empower Collaborative Teams</h2>
        <p>Unify your entire workforce within the system for real-time collaboration on projects. Sign in on-site with time sheet data, streamline payroll for each employee, and foster seamless communication among team members. With siteHUB, teamwork reaches new heights, paving the way for success and exceptional project outcomes.</p>
  
        <Button text={"View Contacts Feature"} link={"/Features/Contacts.html"}/>
      </div>
      <div className='contact__management--images'>
        <img src={teamImage} alt="Construction team becoming more efficient and profitable with siteHUB project management software" />
      </div>
    </div>
  )
}

export default ContactView