import React, {  useState, useRef, useEffect } from 'react'
import { FaLongArrowAltRight } from 'react-icons/fa';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './HomeNav.css';

const HomeNav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [viewFeatures, setViewFeatures] = useState(false);
  const featureRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewFeatures && // Check if viewFeatures is true
        featureRef.current && // Check if featureRef is defined
        !featureRef.current.contains(event.target) && // Click is outside of featureRef
        event.target.textContent !== 'Features' // Click is not on "Features" text
        ) {
          setViewFeatures(false);
        }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [viewFeatures]);

  const resourceRef = useRef(null)
  const [viewResources, setViewResources] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        viewResources && // Check if viewFeatures is true
        resourceRef.current && // Check if featureRef is defined
        !resourceRef.current.contains(event.target) && // Click is outside of featureRef
        event.target.textContent !== 'Resources' // Click is not on "Features" text
      ) {
        setViewResources(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [viewResources]);


  return (
    <header className='nav__header' style={{height: `${(viewFeatures || viewResources) ? '100dvh' : 'unset'}` }}>
    <nav className='siteHub__nav'>

      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          <a href="/">
            <h2>siteHUB</h2>
          </a>
        </div>
        <div className='gpt3__navbar-links_container'>
          <p><a className='span__hover' href="/#home">Home</a></p>
          <p onClick={()=>setViewFeatures(true)}>Features</p>
          <p onClick={()=>setViewResources(true)}>Resources</p>
          <p><a className='span__hover' href="/pricing">Pricing</a></p>
          <p><a className='span__hover' href="/contact">Contact</a></p>
        </div>
      </div>

      <div className='gpt3__navbar-sign'>
        <a href="https://web.sitehubapp.com/">
          <p>Sign in</p>
        </a>
        <a href="https://web.sitehubapp.com/">
          <button  type='button'>Sign up</button>
        </a>
        <div className="toggle__menu">

        {toggleMenu
          ? <RiCloseLine  size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />
        }
        </div>
      </div>
    </nav>
        {viewFeatures && (
          <div class="feature__cont" ref={featureRef}>
          <div class="feature__list">
            <h2>Projects</h2>
            <ul>
              <li>
                <a href="/Features/projectManagement.html">Project management</a>
              </li>
              <li> 
                <a href="/Features/addingProjects.html">Add projects</a>
              </li>
              <li> 
                <a href="/Features/Tasks.html">Tasks</a>
              </li>
              <li> 
                <a href="/Features/Gantt.html">Gantt</a>
              </li>
              <li> 
                <a href="/Features/ProjectCalender.html">Project Calender</a>
              </li>
              <li> 
                <a href="/Features/ProjectBudgets.html">Project Budgets</a>
              </li>

            </ul>
          </div>
          <div class="feature__list">
            <h2>Teams</h2>
            <ul>
              <li> 
                <a href="/Features/Contacts.html">Contacts</a>
              </li>
              <li> 
                <a href="/Features/TimeSheets.html">Time sheets</a>
              </li>
              <li> 
                <a href="/Features/Payroll.html">Payroll</a>
              </li>
              <li> 
                <a href="/Features/Messages.html">Messages</a>
              </li>
              <li> 
                <a href="/Features/HealthAndSafety.html">Health and safety</a>
              </li>
              <li> 
                <a href="/Features/IncidentReporting.html">Incident reporting</a>
              </li>
            </ul>
          </div>
          <div class="feature__list">
            <h2>Management</h2>
            <ul>
              <li> 
                <a href="/Features/Calender.html">Calender</a>
              </li>
              <li> 
                <a href="/Features/Storage.html">Storage</a>
              </li>
              <li>
                <a href="/Features/Analysis.html">Company Analysis</a>
              </li>
              <li>
                <a href="/Features/Inventory.html">Inventory</a>
              </li>
              <li> 
              <a href="/Features/DailyLogs.html">Daily Logs</a>
            </li>
            </ul>
          </div>
          <div class="feature__list">
            <h2>Customer portal</h2>
            <ul>
              <li>
                <a href="/Features/CustomerPortal.html">Customer portal</a>
              </li>
              <li>
                <a href="/Features/PaymentSoftware.html">Get paid with siteHUB</a>
              </li>
              <li>
                <a href="/Features/SendQuotes.html">Send Quotes</a>
              </li>
              {/* <li>
                <a href="/Features/SendInvoices.html">Send Invoices</a>
              </li>
              <li>
                <a href="/Features/CustomerCommunication.html">Communication</a>
              </li> */}
            </ul>
          </div>
        </div>
        )}
        {viewResources && (
          <div class="feature__cont" ref={resourceRef}>
            <div class="feature__list">
              <h2>Recourses</h2>
              <ul>
                <li>
                  <a href="/Blog/Blog.html">Blog</a>
                </li>
                {/* <li> 
                  <a href="/News/News.html">News</a>
                </li>
                <li> 
                  <a href="/coming-soon/coming-soon.html">Coming soon</a>
                </li>
                <li> 
                  <a href="/how-to's/how-to.html">How to's</a>
                </li> */}
              </ul>
            </div>
            <div class="feature__list">
              <h2>Media</h2>
              <ul>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/sitehubapp/">Instagram</a>
                </li>
                <li> 
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/sitehubapp/">LinkedIn</a>
                </li>
                <li> 
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100091731523402">Facebook</a>
                </li>
                <li> 
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UChaOD_RPlxGs5fOCFx-FkLw">Youtube</a>
                </li>
              </ul>
            </div>
          </div>
        )}
        {toggleMenu && (
          <div className='siteHub__navbar-menu '>
            <div className='siteHub__navbar-menu_container scale-up-center'>
              <p><a className='span__hover' href="/#home">Home</a></p>
              <p><a className='span__hover' href="/Features/Features.html">Features</a></p>
              <p><a className='span__hover' href="/pricing">Pricing</a></p>
              <p><a className='span__hover' href="/Resources.html">Resources</a></p>
              <p><a className='span__hover' href="/contact">Contact</a></p>
            </div>
            <div className='siteHUb-menu_container-links-sign scale-up-center'>
            <a href="https://web.sitehubapp.com/">
                <p>get started</p>
                <button type='button'><FaLongArrowAltRight /></button>
              </a>
            </div>
          </div>
        )}


    </header>
  )
}

export default HomeNav