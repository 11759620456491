import Button from '../Header/Button';
import './cta.css';

const ProjectLink = () => {
  

  return (
    <div className='siteHub__cta--home'>
      <div className='siteHub__cta-content'>
        <h2 style={{ color: '#FFFFFF' }}>World class project management</h2>
      </div>
      <Button clBlack={true} link={"/Features/projectManagement.html"} text={"View Feature"}/>
    </div>
  );
};

export default ProjectLink;




